<template>
  <div class="row align-items-center">
    <div class="col-12 col-sm-6">
      <h5 v-if="total > 0" class="mb-0">
        {{ titlePrefix }}
        Showing {{ results }} of {{ total }}
        {{ titleSuffix }}
      </h5>
    </div>
    <div class="col-12 col-sm-6 text-right">
      <button
        v-if="hasDates"
        type="button"
        class="btn btn-dark btn-sm ml-1"
        @click="removeParam('range')"
      >
        Date Range: {{ hasDates }}
        <font-awesome-icon icon="times" class="ml-2" />
      </button>
      <button
        v-if="initialQ !== ''"
        type="button"
        class="btn btn-dark btn-sm ml-1"
        @click="removeParam('keywords')"
      >
        Keywords: {{ initialQ }}
        <font-awesome-icon icon="times" class="ml-2" />
      </button>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'PaginationResults',
  props: {
    titlePrefix: {
      type: String,
      required: false,
      default: '',
    },
    titleSuffix: {
      type: String,
      required: false,
      default: '',
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1,
    },
    totalPages: {
      type: Number,
      required: false,
      default: 1,
    },
    perPage: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  computed: {
    hasDates() {
      // console.log('PaginationResults computed hasDates() :initalRange', this.initalRange);
      if (this.initalRange.start && this.initalRange.end) {
        const start = dayjs(this.initalRange.start).format('M/D');
        const end = dayjs(this.initalRange.end).format('M/D');

        return `${start}-${end}`;
      }

      return false;
    },
    results() {
      let showOn = 1;

      if (this.currentPage > 1) {
        showOn = (this.currentPage - 1) * this.perPage + 1;
      }

      let showOf = showOn + this.perPage - 1;

      if (showOf > this.total) {
        showOf = this.total;
      }

      if (this.total === 0) {
        showOn = 0;
      }

      return `${showOn} to ${showOf}`;
    },
    q() {
      return this.$store.getters['search/getKeywords'];
    },
    range() {
      return this.$store.getters['search/getRange'];
    },
  },
  data() {
    return {
      initialQ: '',
      initalRange: {},
    };
  },
  created() {
    this.initialQ = this.q;
    this.initalRange = this.range;
  },
  methods: {
    removeParam(param) {
      this.$store.dispatch('search/destroy', param);

      this.$emit('searchQuery');
    },
  },
};
</script>

<style></style>
